@import './variables.scss';
@import './Layouts/index.scss';
@import './Components/index.scss';
@import './Pages/Members/index.scss';
@import './Pages/Report/index.scss';
@import './Pages/Login/index.scss';

body {
	background: $background-main;
}

.background-main-png {
	background-image: url('./../Assets/Img/app_bg.jpg');
	max-width: 100%;
	background-repeat: no-repeat;
  background-size: cover;
	display: block;
	position: absolute;
	width: 100vw;
	height: 100vh;
	left: 0;
	top: 0;
	z-index: -1;
}

.font-button {
	border:hidden;
	background-color: transparent;
}

.form-control:disabled {
	cursor: not-allowed;
}

.select-multiple-input-container input {
	min-width: max-content !important;
	width: 130% !important;
}

.btn-icon{
	border: none;
	background-color: white;
	height: 22px;
}

button, input[type=button] { 
	cursor: pointer;
}

button[disabled], input[type=button][disabled] { 
	cursor: not-allowed;
}

.ut-subtitle {
	font-weight: bold;
	color: #061058;
	font-size: 18px;
	max-width: 500px;
}

.ut-box-subtitle {
	float: left;
	position: relative;
	width: auto;
	top: 10%;
	font-size: px;
}

.colorBox {
	width: 50px;
	height: 50px;
	background: red;
	float: left;
	border-radius: 10px;
	margin: 15px;
	margin-left: 0;
}

.ut-beneficiary-save {
	float: right;
	max-width: 200px;
}

.td-space-table-two-buttons {
	width: 130px;
}

.td-space-table {
	max-width: 190px;
	overflow: hidden;
  flex: 1;
}

.td-space-table-three-buttons {
	max-width: 59%;
}

li.text-pagination a.disabled {
	cursor: not-allowed;
}

.list-group-item+.list-group-item {
	line-height: 1.5em;
  overflow: hidden;
}

.react-datepicker-wrapper {
	display: block !important;
}

.react-datepicker-wrapper input{
	height: 34px;
}

.inline .react-datepicker-wrapper  {
	width: 73% !important;
	display: inline-block!important;
}

.react-datepicker-wrapper input {
	border-radius: 8px;
	background: #ededed;
	border: none;
}

.react-datepicker-wrapper input:focus {
	color: #212529;
	background-color: #fff;
	border-color: #86b7fe;
	outline: 0;
	box-shadow: 0 0 0 0.25rem RGB(13 110 253 / 25%);	
}

.dropbtn {
	cursor: pointer;
  background-color: $main-color;
  color: white;
  padding-top: 10px;
  font-size: 16px;
  border: none;
}

.dropdown {
	width: fit-content;
	position: relative;
	display: inline-block;
	padding: 0 15px;
}

.dropdown button {
	margin: auto;
	display: block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  background-color: $main-color;
  display: none;
  position: absolute;
  min-width: max-content;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  z-index: 1;
	padding-top: 16px;
	right: -11px;
}

/* Links inside the dropdown */
.dropdown-content a, .dropdown-content button {
  color: black;
  padding: 10px 8px;
  text-decoration: none;
  display: block;
	background-color: $background-main;
	margin: 0;
	width: 100%;
	border: 0;
	text-align: left;
}

/* Change color of dropdown links on hover */
.dropdown-content a:hover, .dropdown-content button:hover {background-color: #ddd;}

/* Show the dropdown menu on hover */
.dropdown:hover .dropdown-content {display: block;}

.member-dashboard .dashboard {
	background: white;
	box-shadow: 2px 2px 2px 1px rgba(0, 0, 0 , 0.2);
	border-radius: 25px;
	height: max-content;
	color: $medium_grey;
}

.member-dashboard .dashboard legend {
    color: #061058;
    font-weight: 600;
    font-size: 20px;
}

.member-dashboard .dashboard label {
	word-wrap: break-word;
	width: 100%;
	color: $main-color;
}

.gray-button{
	background-color: $medium_grey;
	color: white;
	text-decoration: none;
	padding: 10px;
	padding-top: 14px;
	border-radius: 10px;
}

.pieChartResume{
	top: -57%;
	position: relative;
	text-align: center;
	width: fit-content;
	margin: auto;
}
.policyColor{
	width: 60px;
	height: 60px;
	float: right;
	border-radius: 20px;
}

.deductions table.table thead {
	color: white;
	background: #f31900;
	border-bottom: hidden;
}
.deductions table.table thead th:first-child {
	border-top-left-radius: 10px;
	border-bottom-left-radius: 10px;
}
.deductions table.table thead th:last-child {
	border-top-right-radius: 10px;
	border-bottom-right-radius: 10px;
}
.deductions{
	padding: 2.5%;
}
.gray-label{
	color: $medium_grey;
	font-size: 20px;
}
.deductions table.table>:not(caption)>*>* {
	padding: 1rem 1rem;
}
.member-dashboard .table-value{
	background: $light-background;
	color: $medium_grey;
	border-radius: 5px;
	padding: 5px 10px;
}
.member-dashboard .table-cell {
	margin: 1% auto;
}
fieldset{
	max-width: 100%;
}
.text-right {
	text-align: right;
}

.text-left {
	text-align: left;
}

.text-center {
	text-align: center;
}

.capitalize::first-letter {
	text-transform:capitalize;
}
.row{
	max-width: 100%;
	width: 100%;
	margin: auto;
}
.dashboard{
	padding: 3rem;
}
.show-on-mobile{
	display: none;
}
.form-check-input-checkbox{
	bottom: -13px;
	position: relative;
}
.date-dropdown, .search-dropdown {
	float: right;
	display: block;
	width: 14px;
	margin-top: -16px;
	bottom: -26px;
	position: relative;
	font-size: 14px;
	margin-right: 12px;
	z-index: 1;
}

table tbody tr:nth-child(odd) td:first-child {
	background: #faa096;
}
.accordion a.list-group-item.list-options:not(.logout) {
	font-weight: 400;
	margin-left: 20px;
	text-transform:capitalize;
}
.logout, .logout h2{
	font-size: 16px;
	font-weight: bold;
}

@media only screen and (min-width: 769px) {
	.ut-content{
		padding-left: 25px;
	}
	.tab-content {
		padding-left: 25px;
	}
	.desktop-mt-5 {
		margin-top: 3rem !important;
	}
}

@media only screen and (max-width: 768px) {
	.mobile-mt-5p {
		margin-top: 5px;
	}
	.mobile-mt-4 {
			margin-top: 1.5rem!important;
	}
	.accordion-tree {
		padding: 0 15%;
	}
	.accordion-item {
		margin-bottom: 10px;
	}
	*{
		font-size: 13px;
	}
	.accordion * {
		font-size: 16px;
	}
	.mobile-col-offset-1 {
		margin-left: 8.33333333%;
	}

	.mobile-col-offset-2 {
		margin-left: 16.66666666%;
	}

	.pagination .page-link {
		margin: 0 !important;
    padding: 10px 0px;
	}

	.btn-icon img{
		width: 30px;
	}
	.check-option{
		margin: 0 !important;
		padding: 0 !important;
		font-size: 12px;
	}
	.text-number-elements-table{
    font-size: 11px;
	}

	.pagination a{
    font-size: 11px;
    width: 80px;
	}
	.pagination  img.left{
    margin: 0;
    padding: 0;
    margin-right: 0 !important;
    float: left;
	}
	.pagination  img.next{
    margin: 0;
    padding: 0;
    margin-right: 0 !important;
    float: right;
	}
	.table-responsive{
		margin-top: 1rem;
	}
	.table-responsive th {
		font-size: 13px;
		line-height: 15px;
    text-align: center;
		white-space: nowrap;
	}
	.table-responsive tbody tr td{
		font-size: 11px;
    padding: 0.25rem;
		white-space: nowrap;
	}
	.table-responsive thead tr th {
		color: $main-color;
		text-align: left;
	}
	.table>:not(:first-child){
		border: none;
	}
	.hide-on-mobile{
		display: none;
	}
	.show-on-mobile{
		display: block;
	}
	.dashboard{
		padding: 2rem;
	}
	.title-layout-header-page{
		font-size: 27px;
		margin-bottom: 0;
	}
	.pie_chart svg:first-child{
		width: 100% !important;
	}
	.p-5{
		padding: 1rem !important;
	}
	.ReactModal__Content{
		max-width: 100%;
	}
	.modal-footer>*{
		margin: auto !important;
	}
}

.pagination { 
	justify-content: center;
	display: flex;
}

.search-dropdown {
	color: $medium-grey;
	font-size: 16px;
}

.search-dropdown-input {
	background-color: $light-grey;
	height: 34px;
}

#unionInformationForm{
	max-height: 83vh;
}

.input-icons {

	width: 100%;
	margin-bottom: 10px;

	img {
			position: absolute;
			width: 20px;
			margin-top: 5px;
			margin-left: 5px;
	}
		
	input {
			padding-left: 20px;
	}
}