.nav-pills .nav-link.active, .nav-pills .show>.nav-link {
	color: $main-color;
	background-color: $background-main;
	border-bottom-style: solid;
	border-bottom-width: medium;
	font-weight: bold;
	border-radius: 0px;
}

.requiredField {
	color: red;
}

.nav-link {
	color: $main-color;
}

.nav-link .active {
	background-color: none;
}

.tab-content {
	background: white;
	border-radius: 10px;
	margin-bottom: 30px;
	padding-top: 10px;
	padding-bottom: 10px;
}

// ######### MEMBERS ###########

.title-label-section-forms {
	color: $secondary-color;
	font-weight: bold;
}

.form-label {
	color: $main-color;
	font-weight: 500;
}

.form-label-grey {
	color: $light-grey;
	font-weight: 500;
}

.form-control {
	border-radius: 8px;
	background: $background-main;
	border: none;
}

.form-select {
	border-radius: 8px;
	background: $background-main;
	background: url("../../../Assets/Img/Icons/chevron-down.svg") no-repeat 95% $background-main;
	border: none;
}

.option-select {
	background: $background-main;
	position: relative;
	bottom: 50px;
}

.form-control::placeholder {
	color: $main-color;
	font-weight: 400;
	opacity: 0.5;
}

.subtitleLabels {
	font-size: 12px;
}

.subtitleLabelSectionForms {
	color: $main-color;
	font-weight: bold;
	font-size: 10.2px;
}

.optionDefaultForSelect {
	font-weight: 400;
	opacity: 0.5;
}

.btn-default {
	width: 100%;
	border: none;
	border-radius: 8px;
	padding-top: 10px;
	padding-bottom: 10px;
	font-weight: 600;
}

.btn-blue {
	background: $main-color;
}
.btn-dark-grey {
	background: $dark-grey;
}
.btn-medium-grey {
	background: $medium_grey;
}
.btn-light-grey {
	background: $light-grey;
	color: grey;
}
.btn-orange {
	background: $secondary-color;
}

.btn-blue:hover {
	background: $main-color;
}
.btn-dark-grey:hover {
	background: $dark-grey;
}
.btn-medium-grey:hover {
	background: $medium_grey;
}
.btn-light-grey:hover {
	background: $light-grey;
	color: grey;
}
.btn-orange:hover {
	background: $secondary-color;
}

.btn-blue:focus {
	background: $main-color;
	border: none;
	border-style: none;
    outline:none !important;
    outline-width: 0 !important;	
}
.btn-dark-grey:focus {
	background: $dark-grey;
	border: none;
	border-style: none;
    outline:none !important;
    outline-width: 0 !important;	
}
.btn-light-grey:focus {
	background: $light-grey;
	border: none;
	color: grey;
	border-style: none;
    outline:none !important;
    outline-width: 0 !important;	
}
.btn-medium-grey:focus {
	background: $medium_grey;
	border: none;
	color: grey;
	border-style: none;
    outline:none !important;
    outline-width: 0 !important;	
}
.btn-orange:focus {
	background: $secondary-color;
	border: none;
	border-style: none;
    outline:none !important;
    outline-width: 0 !important;	
}

.icon-members-web {
	font-size: 12px;
}

.label-input-radio {
	position: relative;
	top: 3px;
}

.form-check-input {
    border: 2px solid $main-color;
}

.form-check-input:checked {
    background-color: white;
    border: 2px solid $main-color;
    padding: 7px;
}

.form-check-input:checked[type=radio] {
    background-image: url("../../../Assets/Img/Icons/point-radio-input.svg");
    padding: 7px;
}

.form-check-input[type=radio] {
    border-radius: 50%;
    border: 2px solid $main-color;
    padding: 7px;
}

.form-check-input-checkbox {
    border: 2px solid $main-color;
}

.form-check-input-checkbox:checked {
    background-color: white;
    border: 2px solid $main-color;
    padding: 7px;
}

.form-check-input-checkbox:checked[type=checkbox] {
    background-image: url("../../../Assets/Img/Icons/point-radio-input.svg");
    padding: 7px;
}

.form-check-input-checkbox[type=checkbox] {
    border-radius: 50%;
    border: 2px solid $main-color;
    padding: 7px;
}

