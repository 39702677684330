.title-component-upload-template {
	font-weight: bold;
	color: $main-color;
	font-size: 20px;
}

.text-component-upload-template {
	color: $medium_grey;
}

.label-component-upload-template {
	font-weight: bold;
	color: $main-color;
	font-size: 18px;	
}

.btn-component-select-file {
	background: white;
	border: 1px solid black !important;
	border-radius: 0px !important;
	color: $main-color;
}

.btn-component-select-file:hover {
	background: white;
	border: 1px solid black;
	color: $main-color;
}

.btn-component-select-file:focus {
	background: white;
	border: 1px solid black;
    outline:none !important;
    outline-width: 0 !important;
    color: $main-color;
}

.text-component-select-file {
	color: $main-color;
	font-size: 15px;
	font-weight: 500;
}


// ##### TABLE ###########

tbody, td, tfoot, th, thead, tr {
    border-color: inherit;
    border-style: none;
    border-width: 0;
}

.td-space-table {
	width: 60px;
}
.td-space-table-large {
	width: 40%;
}

.btn-show-table {
	// height: 20px;
	padding-top: 0px;
	padding-bottom: 0px;
}

.btn-blue-show-table {
	background: $main-color;
	width: 100%;
	border: none;
	border-radius: 12px;
	font-weight: 600;
	margin-top: 40px;
}
.btn-blue-show-table:hover {
	background: $main-color;
}
.btn-blue-show-table:focus {
	background: $main-color;
	border: none;
	border-style: none;
    outline:none !important;
    outline-width: 0 !important;	
}
.btn-orange-show-table:hover {
	background: $secondary-color;
}
.btn-orange-show-table:focus {
	background: $secondary-color;
	border: none;
	border-style: none;
    outline:none !important;
    outline-width: 0 !important;	
}

.table-input-search {
	background: $light-grey;
	border-radius: 7px;
	border: none;
	padding: 5px;
	width: 60%;
	padding-right: 35px;
	padding-left: 15px;
}

.table-input-search::placeholder { 
	color: $main-color;
	padding-left: 10px;
	margin-left: 10px;
}

.icon-table-input-search {
	position: relative;
	right: 12%;
	bottom: 2px;
}

.btn-pagination {
	border: none;
	color: $dark-grey;
}

.text-pagination {
	color: $dark-grey;
}

.icon-arrow-pagination {
	width: 20px;
	position: relative;
	bottom: 1.5px;
}

.text-number-elements-table {
	color: $light-grey;
}

.icon-delete-table {
	width: 20px;
}

.btn-blue-refresh-table {
	background: $main-color;
	width: 150%;
	border: none;
	border-radius: 12px;
	font-weight: 600;
	margin-top: 40px;	
}

.icon-buttom-refresh-layout-header-table {
	width: 20px;
	height: 20px;
}

.btn-delete-table {
	color: red;
	border: none;
	font-weight: bold;
	position: relative;
	bottom: 5px;
}
.btn-delete-table:hover {
	color: red;
	border: none;
	font-weight: bold;
	background: white;
}
.btn-delete-table:active {
	color: red;
	border: none;
	font-weight: bold;
	background: white;
}
.btn-delete-table:focus {
	color: red;
	border: none;
	font-weight: bold;
	background: white;
}
.btn-edit-table {
	color: $main-color;
	border: none;
	font-weight: bold;
	position: relative;
	bottom: 5px;
}
.btn-edit-table:hover {
	color: $main-color;
	border: none;
	font-weight: bold;
	background: white;
}
.btn-edit-table:active {
	color: $main-color;
	border: none;
	font-weight: bold;
	background: white;
}
.btn-edit-table:focus {
	color: $main-color;
	border: none;
	font-weight: bold;
	background: white;
}

@media screen and (max-width: 2000px) {
	.btn-blue-show-table {
		background: $main-color;
		width: 150%;
		border: none;
		border-radius: 12px;
		font-weight: 600;
		margin-top: 40px;
	}
	.btn-orange-show-table {
		background: $secondary-color;
		width: 150%;
		border: none;
		border-radius: 12px;
		font-weight: 600;
		margin-top: 40px;		
	}	
}

@media screen and (max-width: 1115px) {
	.btn-blue-show-table {
		background: $main-color;
		width: 180%;
		border: none;
		border-radius: 12px;
		font-weight: 600;
		margin-top: 40px;
	}	

	.table-input-search {
		background: $light-grey;
		border-radius: 7px;
		border: none;
		padding: 5px;
		width: 55%;
		padding-right: 35px;
		padding-left: 15px;
	}	
}

@media screen and (max-width: 991px) {
	.btn-blue-show-table {
		background: $main-color;
		width: 50%;
		border: none;
		border-radius: 12px;
		font-weight: 600;
		margin-top: 40px;
	}	
}

@media screen and (max-width: 767px) {
	.btn-blue-show-table {
		background: $main-color;
		width: 100%;
		border: none;
		border-radius: 12px;
		font-weight: 600;
		margin-top: 40px;
	}	
}