.login-text{
  color: $main-color;
  font-weight: 800;
  display: block;
  margin: auto;
  width: fit-content;
}
.login-text.title {
  font-size: 35px;
}

.login-text.subtitle {
  font-size: 25px;
}

.login-logo {
  display: block;
  margin: 10% auto 0 auto;
  width: fit-content;
}

.login-form {
  margin: 0 15%;
}

.login-form .requiredField {
 visibility: hidden;
}

.login-help-link{
  color: gray;
  text-decoration: none;
  display: block;
  background: transparent;
  float: right;
  border: none;
}

.login-recaptcha-container{
  width: 100% !important;
  text-align: center;
}

.login-recaptcha-container div {
  width: 100% !important;
  text-align: center;
}

.login-screen{
  width: 100%;
  height: 100%;
}

.login-page{
  height: 100vh;
  width: 100vw;
  background: white;
}

.login-logo{
  width: 150px;
}

.login-danger{
  text-align: center;
  display: block;
}

@media screen and (max-width: 768px) {
  .login-form {
    margin: 0 5%;
  }
}