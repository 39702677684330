// ########### HEADER PAGE ###############

.icon-input-search {
	position: relative;
	right: 7%;
	bottom: 2px;
}

.title-layout-header-page {
	color: $main-color;
	font-weight: 800;
	font-size: 45px;
}

.content-layout-back-to-dashboard {
	width: 200px;
}

.icon-layout-back-to-dashboard {
	width: 20px;
	height: 20px;
	margin-top: 2px;
	margin-right: 2px;
}

.icon-buttom-refresh-layout-header {
	width: 30px;
	height: 30px;
	margin-top: 2px;
}

.link-layout-back-to-dashboard {
	color: $main-color;
	text-decoration: none;
}

.title-layout-back-to-dashboard {
	color: $main-color;
	font-weight: 500;
	position: relative;
	top: 2px;
}

.buttom-refresh-layout-header {
	background: $main-color;
	width: 45px;
	height: 35px;
	border-radius: 5px;
	border: none;
}

.header-layout-input-search {
	background: $light-grey;
	border-radius: 7px;
	border: none;
	padding: 5px;
	width: 35%;
	padding-right: 35px;
	padding-left: 15px;
}

.header-layout-input-search::placeholder { 
	color: $main-color;
	padding-left: 10px;
	margin-left: 10px;
}

.content-layout-search {
	margin-left: 30px;
	width: 200px;
	padding: 0px;
}

.text-layout-header-search {
	margin-top: 20px;
	color: $main-color;
	font-weight: 500;
	margin-left: 25px;
	margin-right: 15px;
}

// ############ HEADER ############

.info_user {
	margin-right: 2px;
	width: 15%;
	text-align: right;
}

.icon-info-user {
	width: 25px;
	height: 25px;
	padding: 0px;
	position: relative;
	bottom: 1px;
}

.header-bg-color {
	background: $main-color;
}

// ############ MENU DASHBOARD ############

.content-logo {
	height: 90%;
}

.content-pages {
	float: left;
	width: 100%;
}

nav.accordion-tree{
	max-width: 250px;
	margin: auto;
}

.list-group-item {
	font-size: 13px;
	padding-top: 0px;
	padding-bottom: 2px;
}

.logo-on-dashboard {
	width: 60%;
	margin-bottom: 40px;
	padding: 0px;
	margin-right: 20px;
}

.content-menu-titles {
	width: 70%;
	background: red;
}

.content-menu-dashboard {
	width: 100%;
	padding: 20px;
	background: $main-color;
	min-height: 87vh;
	border-radius: 0px 20px 20px 0px;
}

.content-menu-dashboard-show {
	width: 100%;
	padding: 20px;
	background: $main-color;
	min-height: 87vh;
	border-radius: 0px 20px 20px 0px;
}

.content-list-options {
	background: $main-color;
	border: none;
	padding: 0px;
}

.list-options {
	text-decoration: none;
	border: none;
	color: white;
	word-break: break-word;
	background: $main-color;
}

.list-options:focus {
	text-decoration: none;
	border: none;
	font-weight: bold;
	color: white;
	background: $main-color;
}

.current-route {
	font-weight: bold;
}

.accordion-item {
	border: none;
	background: $main-color;
}

.accordion-button:not(.collapsed) {
    box-shadow: none;
}

.accordion-button {
	font-weight: bold;
	width: 100%;
	padding-top: 5px;
	padding-bottom: 10px;
}

.accordion-button:not(.collapsed)::after {
    background-image: url("../../Assets/Img/Icons/arrow-menu.svg");
    transform: rotate(-180deg);
}

.accordion-button::after {
    flex-shrink: 0;
    width: 0.80rem;
    height: 0.80rem;
    margin-left: auto;
    content: "";
    background-image: url("../../Assets/Img/Icons/arrow-menu.svg");
    background-repeat: no-repeat;
    background-size: 0.80rem;
    transition: transform .2s ease-in-out;

}
.accordion-button.collapsed {
	background: $main-color;
	color: white;
	width: 100%;	
}

.accordion-button:not(.collapsed) {
	background: $main-color;
	color: white;
	width: 100%;	
}

@media screen and (max-width: 1115px) {

	.content-menu-dashboard {
		width: 250px;
		padding-left: 0px;
		background: $main-color;
		height: 100vh;
		border-radius: 0px 20px 20px 0px;
	}

	.content-menu-dashboard-show {
		width: 250px;
		padding-left: 0px;
		background: $main-color;
		height: 100vh;
		border-radius: 0px 20px 20px 0px;
	}		
	

	.accordion-button {
		font-weight: bold;
		width: 60%;
		padding-top: 5px;
		padding-bottom: 10px;
	}

	.accordion-button:not(.collapsed) {
	    box-shadow: none;
	}


}

@media screen and (max-width: 992px) {

	.content-menu-dashboard {
		width: 250px;
		padding-left: 0px;
		background: $main-color;
		height: 100vh;
		border-radius: 0px 20px 20px 0px;
		display: none;
	}

	.content-menu-dashboard-show {
		width: 250px;
		padding-left: 0px;
		background: $main-color;
		height: 100vh;
		border-radius: 0px 20px 20px 0px;
	}		
	
	.navbar-expand-lg .navbar-collapse {
	    display: flex!important;
	    flex-basis: auto;
	}

	.info_user {
	    margin-right: 2px;
	    width: 100%;
	    text-align: right;
	}

	.navbar>.container, .navbar>.container-fluid, .navbar>.container-lg, .navbar>.container-md, .navbar>.container-sm, .navbar>.container-xl, .navbar>.container-xxl {
	    display: flex;
	    flex-wrap: nowrap; 
	    align-items: center;
	    justify-content: space-between;
	}


}

.accordion-menu-overlay {
	display: none;
}

.accordion-menu-overlay .overlay-close{
	float: right;
	position: relative;
	display: block;
	background: transparent;
	border: 1px solid white;
	border-radius: 20px;
	height: 20px;
	width: 20px;
	color: white;
	margin: 4% 5%;
	padding: 0;
	font-size: 12px;
	padding-top: 2px;
}

@media screen and (max-width: 700px) { 
	.accordion, .accordion *	{
		z-index: 3;
	}
	nav.content-menu-dashboard-show{
		overflow: auto;
	}
	.accordion-menu-overlay.content-menu-dashboard-show {
		display: block;
		position: fixed;
		top: 0;
		width: 100vw !important;
		height: 100vh;
		background-color: #a8a8a8;
		z-index: 2;
		border-radius: 0;
	}

	.content-menu-dashboard {
		width: 250px;
		padding-left: 0px;
		background: $main-color;
		height: 100vh;
		border-radius: 0px 20px 20px 0px;
		display: none;
	}

	.logo-on-dashboard {
		margin: auto;
	}

	.content-menu-dashboard-show {
		width: 84%  !important;
		padding-left: 0px;
		background: $main-color;
		height: 100vh;
		border-radius: 0 !important;
		position: absolute;
		top: 0;
    margin: 0 !important;
    padding: 0;
		position: fixed;
	}
	.content-menu-dashboard-show .accordion {

	}

	.accordion-button {
		font-weight: bold;
		width: 100%;
		padding-top: 5px;
		padding-bottom: 10px;
	}

	.header-layout-input-search {
		background: $light-grey;
		border-radius: 7px;
		border: none;
		padding: 5px;
		width: 100px;
	}

	.text-layout-header-search {
		margin-top: 20px;
		color: $main-color;
		font-weight: 500;
	}



}

@media screen and (max-width: 575px) { 
	.content-menu-dashboard {
		width: 250px;
		padding-left: 0px;
		background: $main-color;
		height: 100vh;
		border-radius: 0px 20px 20px 0px;
		display: none;
	}

	.content-menu-dashboard-show {
		width: 250px;
		padding-left: 0px;
		background: $main-color;
		height: 100vh;
		border-radius: 0px 20px 20px 0px;
	}

	.accordion-button {
		font-weight: bold;
		width: 100%;
		padding-top: 5px;
		padding-bottom: 10px;
	}

	.text-layout-header-search {
		margin-top: 20px;
		color: $main-color;
		font-weight: 500;
		margin-left: 15px;
		margin-right: 5%;
	}

	.header-layout-input-search {
		background: $light-grey;
		border-radius: 7px;
		border: none;
		padding: 5px;
		width: 200px;
	}

}

@media screen and (max-width: 490px) { 
	.content-menu-dashboard {
		width: 250px;
		padding-left: 0px;
		background: $main-color;
		height: 100vh;
		border-radius: 0px 20px 20px 0px;
		display: none;
	}

	.content-menu-dashboard-show {
		width: 250px;
		padding-left: 0px;
		background: $main-color;
		height: 100vh;
		border-radius: 0px 20px 20px 0px;
	}

	.accordion-button {
		font-weight: bold;
		width: 100%;
		padding-top: 5px;
		padding-bottom: 10px;
	}

	.header-layout-input-search {
		background: $light-grey;
		border-radius: 7px;
		border: none;
		padding: 5px;
		width: 200px;
	}

	.text-layout-header-search {
		margin-top: 20px;
		color: $main-color;
		font-weight: 500;
		margin-left: 10%;
		margin-right: 5%;
	}

}

@media screen and (max-width: 430px) { 
	.content-menu-dashboard {
		width: 220px;
		padding-left: 0px;
		background: $main-color;
		height: 100vh;
		border-radius: 0px 20px 20px 0px;
		display: none;
	}

	.content-menu-dashboard-show {
		width: 220px;
		padding-left: 0px;
		background: $main-color;
		height: 100vh;
		border-radius: 0px 20px 20px 0px;
	}

	.accordion-button {
		font-weight: bold;
		width: 100%;
		padding-top: 5px;
		padding-bottom: 10px;
	}

	.text-layout-header-search {
		margin-top: 20px;
		color: $main-color;
		font-weight: 500;
		margin-left: 20px;
		margin-right: 5%;
	}

	.header-layout-input-search {
		background: $light-grey;
		border-radius: 7px;
		border: none;
		padding: 5px;
		width: 180px;
	}

}


@media screen and (max-width: 320px) { 
	.content-menu-dashboard {
		width: 200px;
		padding-left: 0px;
		background: $main-color;
		height: 100vh;
		border-radius: 0px 20px 20px 0px;
		display: none;
	}

	.content-menu-dashboard-show {
		width: 200px;
		padding-left: 0px;
		background: $main-color;
		height: 100vh;
		border-radius: 0px 20px 20px 0px;
	}

	.accordion-button {
		font-weight: bold;
		width: 100%;
		padding-top: 5px;
		padding-bottom: 10px;
	}

	.text-layout-header-search {
		margin-top: 20px;
		color: $main-color;
		font-weight: 500;
		margin-left: 15px;
		margin-right: 5%;
	}

	.header-layout-input-search {
		background: $light-grey;
		border-radius: 7px;
		border: none;
		padding: 5px;
		width: 100px;
	}

}

@media screen and (max-width: 280px) { 
	.content-menu-dashboard {
		width: 200px;
		padding-left: 0px;
		background: $main-color;
		height: 100vh;
		border-radius: 0px 20px 20px 0px;
		display: none;
	}

	.content-menu-dashboard-show {
		width: 200px;
		padding-left: 0px;
		background: $main-color;
		height: 100vh;
		border-radius: 0px 20px 20px 0px;
	}

	.accordion-button {
		font-weight: bold;
		width: 100%;
		padding-top: 5px;
		padding-bottom: 10px;
	}

	.text-layout-header-search {
		margin-top: 20px;
		color: $main-color;
		font-weight: 500;
		margin-left: 10px;
		margin-right: 0px;
	}

	.header-layout-input-search {
		background: $light-grey;
		border-radius: 7px;
		border: none;
		padding: 5px;
		margin-left: 20px;
		width: 40%;
	}

	.title-layout-header-page {
		color: $main-color;
		font-weight: 800;
		font-size: 35px;
	}

}

.react-datepicker__input-container{
	z-index: 0;
}

.content-menu-dashboard-show{
	z-index: 3;
}